import { ImageProps } from 'next/image'
import { chakra } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import Image, { ImageInterface } from 'components/images/Image'
import { MotionBox } from 'components/Motion'

import useViewportAnimation from 'hooks/useViewportAnimation'

interface ImageParallaxProps {
  src: ImageInterface['src']
  alt: ImageInterface['alt']
  nextConfig: {
    width?: ImageProps['width']
    height?: ImageProps['height']
    layout?: ImageProps['layout']
    objectFit?: ImageProps['objectFit']
    priority?: ImageProps['priority']
  }
  className?: string
  parallax?: number
}

const DEFAULT_RANGE = 60

const ImageParallax = ({ className, src, alt, nextConfig, parallax }: ImageParallaxProps) => {
  const [scale, setScale] = useState(1)

  const range = parallax || DEFAULT_RANGE

  const [progress, ref] = useViewportAnimation(range / 2, range / -2)

  useEffect(() => {
    if (ref?.current) {
      const { height } = ref.current.getBoundingClientRect()

      setScale((height + Math.abs(range)) / height)
    }
  }, [range, ref])

  return (
    <MotionBox
      className={className}
      overflow="hidden"
      css="transform: translateZ(0)" // Safari fix
      ref={ref}
    >
      <MotionBox
        w="100%"
        h="100%"
        style={{
          scale,
          y: progress,
        }}
      >
        <Image w="100%" h="100%" src={src} alt={alt} {...nextConfig} />
      </MotionBox>
    </MotionBox>
  )
}

export default chakra(ImageParallax)

import { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import SEO from 'components/common/Metadata'

import { ContentfulGlobalSettings, ContentfulGraphRoute, ContentfulGraphSEOMetaData } from 'contentful/types'
import colors from 'constants/colors'

type LayoutProps = {
  children: React.ReactNode
  metadata: ContentfulGraphSEOMetaData
  settings: ContentfulGlobalSettings
}

const Layout = ({ children, metadata, settings }: LayoutProps): JSX.Element => {
  const router = useRouter()
  const { routesCollection: { items: routes = [] } = {} } = settings || {}

  useEffect(() => {
    if (router.route === '/work/[slug]') {
      return
    }

    document.body.style.backgroundColor = getBackgroundColor(router.asPath, routes)
  }, [router])

  return (
    <>
      {metadata && <SEO {...metadata} />}

      <Box pos="relative" zIndex="page">
        {children}
      </Box>
    </>
  )
}

function getBackgroundColor(path: string, routes: ContentfulGraphRoute[]) {
  const item = !!routes?.length && routes.find((r) => r?.route === path)
  return item ? item?.backgroundColor : colors.blue_13
}

export default Layout

import { rem } from 'polished'
import dynamic from 'next/dynamic'
import { As, chakra } from '@chakra-ui/react'

import { IconThreeLinesType } from 'components/common/Icons'
import AnimatedSplitText from 'components/AnimatedSplitText'
import { MotionText } from 'components/Motion'

export const EYEBROW_THEMES = {
  light: 'light',
  dark: 'dark',
  white: 'white',
}

interface EyebrowProps {
  text: string
  className?: string
  isStacked?: boolean
  themeType?: keyof typeof EYEBROW_THEMES
  tag?: As
}

const IconThreeLines = dynamic<IconThreeLinesType>(() =>
  import('components/common/Icons').then((mod) => mod.IconThreeLines),
)

const Eyebrow = ({ className, text, isStacked, tag, themeType }: EyebrowProps) => {
  return text ? (
    <MotionText
      as={tag}
      className={className}
      display="flex"
      flexDirection={isStacked && 'column'}
      alignItems={isStacked ? 'center' : 'flex-end'}
      fontFamily="mono"
      fontSize={{ base: rem(10) }}
      letterSpacing={{ base: '0.2em' }}
      lineHeight={{ base: rem(10) }}
      textTransform="uppercase"
      opacity={0.8}
      whileHover="hover"
    >
      <IconThreeLines
        w={{ base: rem(16) }}
        h={{ base: rem(12) }}
        mr={!isStacked && { base: rem(9) }}
        mb={isStacked && { base: rem(10) }}
        pb={{ base: rem(1) }}
        themeType={themeType}
      />

      <AnimatedSplitText perCharacter={true}>{text}</AnimatedSplitText>
    </MotionText>
  ) : (
    <></>
  )
}

export default chakra(Eyebrow)

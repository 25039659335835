import { rem } from 'polished'
import { chakra, Box, As, forwardRef } from '@chakra-ui/react'

import { BreakpointKeysInterface } from 'constants/breakpoints'

interface ContainerSlantedProps {
  children: React.ReactNode
  slant: ContainerSlantedInterface['slant']
  className?: string
  tag?: As
}

export interface ContainerSlantedInterface {
  slant: {
    [key in keyof BreakpointKeysInterface]?: {
      tl?: number
      tr?: number
      bl?: number
      br?: number
    }
  }
}

const ContainerSlanted = forwardRef<ContainerSlantedProps, 'div'>(({ children, className, slant, tag }, ref) => {
  return (
    <Box as={tag} className={className} p={createPadding(slant)} clipPath={createClipPaths(slant)} ref={ref}>
      {children}
    </Box>
  )
})

function createClipPaths(obj: ContainerSlantedProps['slant']) {
  const entries = Object.entries(obj)

  const clipPaths = entries.reduce((total, entry) => {
    const bp = entry[0] as keyof BreakpointKeysInterface
    const points = {
      tl: entry[1]?.tl || '0px',
      tr: entry[1]?.tr || '0px',
      bl: entry[1]?.bl || '0px',
      br: entry[1]?.br || '0px',
    }

    return {
      ...total,
      [bp]: `
        polygon(0 ${rem(points.tl)},
        100% ${rem(points.tr)},
        100% calc(100% - ${rem(points.bl)}),
        0 calc(100% - ${rem(points.br)}))
      `,
    }
  }, {})

  return clipPaths
}

function createPadding(obj: ContainerSlantedProps['slant']) {
  const entries = Object.entries(obj)

  const padding = entries.reduce((total, entry) => {
    const bp = entry[0] as keyof BreakpointKeysInterface

    const topLeft = entry[1]?.tl || 0
    const topRight = entry[1]?.tr || 0
    const bottomLeft = entry[1]?.bl || 0
    const bottomRight = entry[1]?.br || 0

    const diffTop = Math.abs(topLeft - topRight)
    const diffBottom = Math.abs(bottomLeft - bottomRight)

    return {
      ...total,
      [bp]: `${rem(diffTop)} 0 ${rem(diffBottom)}`,
    }
  }, {})

  return padding
}

export default chakra(ContainerSlanted)

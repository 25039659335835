import React from 'react'
import { ContentfulGraphSEOMetaData } from '../../contentful/types'
import Head from 'next/head'

function Metadata(props: ContentfulGraphSEOMetaData): JSX.Element {
  const { title, description, metaShareImage, twitterShareImage } = props
  const url = 'https://www.wildlife.la'
  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={url} />
      <meta name="twitter:creator" content="@wildlifela" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterShareImage?.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={metaShareImage?.url} />
      <meta property="og:locale" content="en_US" />
      <meta name="description" content={description} />
    </Head>
  )
}

export default Metadata
